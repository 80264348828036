import { joiResolver } from '@hookform/resolvers/joi';
import { Box, CircularProgress } from '@mui/material';
import { addDays, startOfDay, format, parse } from 'date-fns';
import Joi from 'joi';
import { useState, useEffect, useCallback } from 'react';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import DbgSelect from '../../components/inputs/DbgSelect';
import { useApplicationContextState } from '../../../contexts/ApplicationContext';
import { ArrayUtils } from '../../../utilities/ArrayUtility';
import { contractAdPlannerExportService } from '../../../services/contract/contractAdPlannerExportService';
import { Button, ConfirmationDialog, HttpErrorResponse, TextInput } from '@dierbergs-markets/react-component-library';
import { id } from 'date-fns/locale';
import { enqueueSnackbar } from 'notistack';
import {
  IAdPlannerExport,
  IAdPlannerExportModule,
  IContract,
  IContractAdPlannerExportContainer,
  IItem,
  IPricing,
  IShipperContainedItemModel,
} from '../../../models';
import dayjs from 'dayjs';
import { contractPricingService, contractTermService } from '../../../services';
import { allowedKeys } from './components/dialogs/ContractTasksCommon';
import { DbgLoadingSpinner } from '../../components/shared/DbgLoadingSpinner';
import { AdPlannerExportPageStyles } from './styles/AdPlannerExportPageStyles';
import PageHeader from '../../layout/components/PageHeader';
import { DbgButtonSize } from '../../../models/enums';
import { DbgRoundedButton } from '../../components/buttons/DbgRoundedButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RouteEnum } from '../../layout/PageRouter';
import PageBody from '../../layout/components/PageBody';
import SelectedStoresIconPopover from './components/SelectedStoresIconPopover';

interface IAdExportParamsFormProps {
  contract: IContract;
  startDate: string | undefined;
  adSiteId: number | undefined;
  setResult: (value: IContractAdPlannerExportContainer) => void;
  setLoading: (value: boolean) => void;
  setStartDate: (value: string) => void;
  setAdSiteId: (value: number) => void;
}
interface IAdPlannerExportQueryForm {
  adSiteId: number;
  startDate: string;
}

interface IAdPlannerExportGroupNamesForm {
  groups: {
    name: string;
    comment: string;
  }[];
}

interface IPureGroup {
  header: IAdPlannerExport;
  subGroups: IAdPlannerExport[];
}

const { styles } = AdPlannerExportPageStyles;
//converted from the VB script in AdPlannerInput.asp
//try to keep the original logic intact.
function getAdStartDate() {
  const curDate = startOfDay(new Date());
  const curDay = curDate.getDay(); // getDay() returns 0 for Sunday, 1 for Monday, etc.

  switch (curDay) {
    // Sun
    case 0:
      return addDays(curDate, -5); //javascript is 0 based, so the offset needs an extra -1
    // Mon, Tue, Wed, Thu, Fri, Sat
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return addDays(new Date(), 2 - curDay);
    default:
      return new Date();
  }
}

function getUtcDate(input: Date) {
  return new Date(input.getTime() + input.getTimezoneOffset() * 60000);
}

const AdExportParamsForm = (props: IAdExportParamsFormProps) => {
  const { contract, setResult, setLoading } = props;
  const [options, setOptions] = useState<string[]>([]);
  const { referenceData } = useApplicationContextState();

  useEffect(() => {
    const WeeksBack = 84; //12 * 7 Days
    const WeeksOut = 112; //16 * 7 Days
    const StartDate = addDays(getAdStartDate(), -WeeksBack);

    const optionList: string[] = [];
    for (let i = 0; i <= WeeksBack + WeeksOut - 1; i += 7) {
      const optionDate = addDays(StartDate, i);
      optionList.push(format(optionDate, 'M/d/yyyy'));
    }
    setOptions(optionList);
  }, []);

  const defaultValues: IAdPlannerExportQueryForm = {
    startDate: props.startDate ?? format(getAdStartDate(), 'M/d/yyyy'),
    adSiteId: props.adSiteId ?? 1,
  };

  const schema = Joi.object({
    startDate: Joi.required().messages({ 'any.empty': 'Start date is required.' }),
    adSiteId: Joi.required().messages({ 'any.required': 'Ad site is required.' }),
  });

  const resolver = joiResolver(schema, { abortEarly: false, allowUnknown: true }, { mode: 'async' });

  const onSubmit: SubmitHandler<IAdPlannerExportQueryForm> = async ({ startDate, adSiteId }: IAdPlannerExportQueryForm) => {
    setLoading(true);
    const response = await contractAdPlannerExportService.getAdPlannerExport(
      contract.contractId,
      dayjs(parse(startDate, 'MM/dd/yyyy', new Date())).toDate(),
      adSiteId
    );
    if (response instanceof HttpErrorResponse) {
      enqueueSnackbar('Error retrieving ad planner export.', { variant: 'error' });
    } else {
      setResult(response as IContractAdPlannerExportContainer);
    }
    setLoading(false);
    props.setStartDate(startDate);
    props.setAdSiteId(adSiteId);
  };

  const { handleSubmit, control } = useForm<IAdPlannerExportQueryForm>({ defaultValues, resolver });

  return (
    <Box>
      <Box sx={[styles.titleContainer]}>
        <Box sx={[styles.title]}>Ad Planner Export</Box>
        <Box sx={[styles.subTitle]}>{`${contract.terms.vendorContractNumber}(${contract.contractId})`}</Box>
      </Box>
      <Box sx={[styles.search]}>
        <Controller
          control={control}
          name={'startDate'}
          render={({ field }) => (
            <DbgSelect
              id={'startDate'}
              label="Ad Starting"
              sx={styles.startDate}
              items={options.map((a) => ({ text: a, value: a }))}
              onChange={field.onChange}
              value={field.value || ''}
            />
          )}
        />
        <Controller
          control={control}
          name={'adSiteId'}
          render={({ field }) => (
            <DbgSelect
              id={'adSiteId'}
              label="Ad Site"
              sx={styles.adSite}
              items={ArrayUtils.orderBy(referenceData!.adSites.all, (x) => x.name).map((i) => {
                return { text: `${i.name}`, value: i.adSiteId };
              })}
              onChange={field.onChange}
              value={field.value || ''}
            />
          )}
        />
        <Box>
          <Button id={`exportQuery-${id}-submit`} text="Load" variant="rounded-sides" color="blue" onClick={handleSubmit(onSubmit)}></Button>
        </Box>
      </Box>
    </Box>
  );
};

export default function AdPlannerExportPage() {
  const { id } = useParams();
  const [exporting, setExporting] = useState(false);
  const { referenceData } = useApplicationContextState();
  const [loading, setLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [contractAdPlannerExportContainer, setContractAdPlannerExportContainer] = useState<IContractAdPlannerExportContainer | null>(null);
  const [pureAdGroups, setPureAdGroups] = useState<IPureGroup[]>([]);
  const [adModules, setAdModules] = useState<{ header: IAdPlannerExport; contentItems: IAdPlannerExportModule[] }[]>([]);
  const [contactItems, setContractItems] = useState<IItem[]>([]);
  const [contractComment, setContractComment] = useState<string>('');
  const [contactPricing, setContractPricing] = useState<IPricing[]>([]);
  const [contract, setContract] = useState<IContract | null>(null);
  const [showUnsavedChanges, setShowUnsavedChanges] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>();
  const [adSiteId, setAdSiteId] = useState<number>();
  const navigate = useNavigate();
  const schema = Joi.object({
    groups: Joi.array().items(
      Joi.object({
        name: Joi.string().required().label('Group Name'),
        comment: Joi.string().allow(null, '').max(100).label('Comment'),
      })
    ),
  });

  const resolver = joiResolver(schema);

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<IAdPlannerExportGroupNamesForm>({ resolver, defaultValues: { groups: [] } });
  const { fields, append } = useFieldArray({
    control,
    name: 'groups',
  });

  useEffect(() => {
    if (loading) {
      reset();
    }
  }, [loading]);

  const exportSubmit = async (data) => {
    setExporting(true);
    const contractNumber = pureAdGroups[0]?.subGroups[0]?.contractNumber ?? '';
    const toSaveAdGroups = pureAdGroups.map((a, index) => ({
      ...a,

      header: {
        adGroupName: data.groups[index].name,
        adGroupComment: data.groups[index].comment,
      },
      subGroups: a.subGroups.map((sub) => ({ ...sub, adGroupName: undefined, comment: undefined })),
    }));

    const toSaveContainer = {
      contractId: contract?.contractId,
      contractNumber: contractNumber,
      adStartDate: startDate,
      adSiteId: adSiteId,
      adGroups: toSaveAdGroups,
      adModules,
    };
    const response = await contractAdPlannerExportService.submitAdPlannerExport(+id!, {
      exportedJSON: JSON.stringify(toSaveContainer),
    });
    setExporting(false);
    setSubmitted(true);

    if (response instanceof HttpErrorResponse) {
      enqueueSnackbar('Error exporting ad planner contract.', { variant: 'error' });
    } else {
      enqueueSnackbar('Successfully exported contract.');
    }
  };

  const getItemByUpc = useCallback(
    (upc: string) => {
      return contactItems.find((a) => a.upc === +upc);
    },
    [contactItems]
  );

  const getShipperContainerTotalCountByContentItemUpc = useCallback(
    (orderCode: string, upc: string) => {
      const shipper = contract?.terms.contractItems.find(
        (a) => a.childData && a.item?.orderCode === orderCode && a.childData.find((b) => b.item?.upc == +upc)
      );
      const total = shipper?.childData!.reduce((acc, cur) => acc + (cur as unknown as IShipperContainedItemModel).contentUPCQty, 0);
      return total! / (shipper?.childData?.length ?? 1);
    },
    [contract]
  );

  const getShipperContainerByOrderCode = useCallback(
    (orderCode: string) => {
      return contract?.terms.contractItems.find((a) => a.item?.orderCode === orderCode);
    },
    [contract]
  );

  const getContentItemQuantityUpc = useCallback(
    (orderCode: string, upc: string) => {
      return (
        (contract?.terms.contractItems.find((a) => a.item?.orderCode === orderCode)?.childData ?? []).find(
          (b) => (b as unknown as IShipperContainedItemModel).item?.upc == +upc
        ) as unknown as IShipperContainedItemModel
      ).contentUPCQty;
    },
    [contract]
  );

  const findSkuForUpc = useCallback((upc) => contactItems.find((a) => a.upc == upc)?.sku, [contactItems]);

  const findPricingCommentForUpcs = useCallback(
    (upcs: string) => {
      const upc = upcs.split(',')[0];
      const sku = findSkuForUpc(upc);
      return contactPricing.find((a) => a.items.find((b) => b.sku == sku))?.comments;
    },
    [contactPricing, findSkuForUpc]
  );

  const loadContract = async (id: string) => {
    const contractResponse = await contractTermService.getContract(+id);

    if (contractResponse instanceof HttpErrorResponse) {
      enqueueSnackbar('Error retrieving contract.', { variant: 'error' });
    } else {
      const contract = contractResponse as IContract;
      setContract(contract);
      setContractItems(contract.terms.contractItems.flatMap((ci) => [ci.item!, ...(ci.childData?.map((c) => c.item!) ?? [])]));
      setContractComment(contract.terms.comments ?? '');
    }
  };
  const loadPricing = async (id: string) => {
    const pricingResponse = await contractPricingService.getPricing(+id);
    if (pricingResponse instanceof HttpErrorResponse) {
      enqueueSnackbar('Error retrieving contract pricing.', { variant: 'error' });
    } else {
      setContractPricing(pricingResponse as IPricing[]);
    }
  };

  const hasAdGroups = useCallback(() => pureAdGroups.length > 0, [pureAdGroups]);
  const hasAdModules = useCallback(() => adModules.length > 0, [pureAdGroups]);

  async function handleReturnToAllContracts() {
    if ((hasAdGroups() || hasAdModules()) && !submitted) {
      setShowUnsavedChanges(true);
    } else {
      navigate(RouteEnum.Dashboard);
    }
  }
  useEffect(() => {
    (async () => {
      if (id) {
        setLoading(true);
        await Promise.all([loadContract(id), loadPricing(id)]);
        setLoading(false);
      }
    })();
  }, [id]);

  useEffect(() => {
    const pureAdGroupsLocal = (contractAdPlannerExportContainer?.adGroups.filter((a) => a.adGroupName.startsWith('!!!')) ?? [])
      .map((a) => {
        const pricingComment = findPricingCommentForUpcs(a.upCs);
        const pricingCommentStr = pricingComment ? `- ${pricingComment}` : '';
        return {
          ...a,
          comment: `${contractComment ?? ''}${pricingCommentStr}`,
        };
      })
      .sort((a, b) => a.adGroupName.localeCompare(b.adGroupName))
      .reduce((acc: IPureGroup[], cur: IAdPlannerExport) => {
        const lastGroup = acc.length > 0 ? acc[acc.length - 1] : null;
        if (lastGroup?.header.adGroupName === cur.adGroupName) {
          lastGroup.subGroups.push(cur);
        } else {
          const newGroup = { header: cur, subGroups: [cur] };
          acc.push(newGroup);
        }
        return acc;
      }, []);
    setPureAdGroups(pureAdGroupsLocal);
    //as to use this to dynamically append. Cannot rely on the defaultValues in useForm
    append(pureAdGroupsLocal.map((a) => ({ name: a.header?.adGroupName, comment: a.header?.comment ?? '' })));
    setAdModules(
      contractAdPlannerExportContainer?.adGroups
        .filter((a) => !a.adGroupName.startsWith('!!!'))
        .map((header) => {
          const contentItems = contractAdPlannerExportContainer.adModules
            .filter((b) => b.moduleNumber === header.adGroupName)
            .map((c) => {
              const cases =
                getContentItemQuantityUpc(header.adGroupName, c.upc) /
                (getShipperContainerTotalCountByContentItemUpc(header.adGroupName, c.upc) ?? 0);
              return {
                ...c,
                cases,
              };
            });
          return { header, contentItems };
        }) ?? []
    );
    setTimeout(() => {
      const element = document.getElementById(`txtgroups.0.name`);
      element?.focus();
    }, 50);
  }, [contractAdPlannerExportContainer]);

  if (loading) {
    return (
      <Box sx={[styles.centeredMessage]}>
        <DbgLoadingSpinner sx={styles.centeredMessage.spinner} id={'adPlannerExportLoading'} />
        <Box>Loading ...please wait.</Box>
      </Box>
    );
  }
  return (
    <>
      <PageHeader>
        <Box sx={styles.contractPageHeader}>
          <DbgRoundedButton id="BackToDashboard" onClick={handleReturnToAllContracts} sx={styles.headerBtn} dbgButtonSize={DbgButtonSize.Large}>
            <ArrowBackIcon sx={styles.headerBtnIcon} />
          </DbgRoundedButton>
        </Box>
      </PageHeader>
      <PageBody>
        <Box sx={[styles.root]}>
          <Box sx={[styles.header]}>
            {contract && (
              <AdExportParamsForm
                contract={contract}
                setResult={setContractAdPlannerExportContainer}
                setLoading={(value) => {
                  setLoading(value);
                  setSubmitted(false);
                }}
                startDate={startDate}
                adSiteId={adSiteId}
                setStartDate={setStartDate}
                setAdSiteId={setAdSiteId}
              />
            )}
          </Box>
          <Box sx={[styles.content]}>
            {fields.length > 0 && (
              <>
                <Box sx={[styles.subTitle, styles.sectionSpace]}> Groups</Box>

                {pureAdGroups.map((group, index) => {
                  return (
                    <Box sx={[styles.subSectionSpace]} key={`group-${index}`}>
                      <Box sx={[styles.propertyDetails, styles.leftDetails]}>
                        <Box sx={[styles.groupNameAndCommentContainer]}>
                          <Box sx={[styles.groupNameContainer]}>
                            <Controller
                              name={`groups.${index}.name`}
                              control={control}
                              defaultValue={fields[index].name}
                              render={({ field }) => (
                                <TextInput
                                  {...field}
                                  id={`groups.${index}.name`}
                                  label="Group Name"
                                  allowedkeys={allowedKeys}
                                  tabIndex={index + 1}
                                  onChange={field.onChange}
                                  value={field.value || ''}
                                  errorMessage={(errors.groups ? errors.groups[index] : (null as any))?.name?.message}
                                  placeholder="Group Name"
                                />
                              )}
                            />
                          </Box>
                          <Box sx={[styles.groupCommentContainer]}>
                            <Controller
                              name={`groups.${index}.comment`}
                              control={control}
                              defaultValue={fields[index].comment}
                              render={({ field }) => (
                                <TextInput
                                  {...field}
                                  id={`groups.${index}.comment`}
                                  label="Comment"
                                  allowedkeys={allowedKeys}
                                  tabIndex={index + 2}
                                  onChange={field.onChange}
                                  errorMessage={(errors.groups ? errors.groups[index] : (null as any))?.comment?.message}
                                  value={field.value || ''}
                                  placeholder="Comment"
                                />
                              )}
                            />
                          </Box>
                          <Box sx={[styles.groupStoresContainer]}>
                            <SelectedStoresIconPopover stores={{ storeGroupIds: [], storeIds: group.header.stores.split(',').map(parseInt) }} />
                          </Box>
                        </Box>
                      </Box>
                      <Box sx={[styles.rightDetails]}>
                        {group.subGroups.map((subgroup) => {
                          return (
                            <table>
                              <tr>
                                <td>
                                  <Box component="table" sx={[styles.gridTable]}>
                                    <thead>
                                      <th>srp</th>
                                      <th>cc</th>
                                      <th>oi</th>
                                      <th>bb</th>
                                      <th>cmap</th>
                                      <th>scan</th>
                                      <th>start</th>
                                    </thead>
                                    <tr>
                                      <td>0/0.00</td>
                                      <td>{subgroup.caseListCost?.toFixed(2)}</td>
                                      <td>{subgroup.oiAllow?.toFixed(2)}</td>
                                      <td>{subgroup.billback?.toFixed(2)}</td>
                                      <td>{subgroup.cmap?.toFixed(2)}</td>
                                      <td>{subgroup.scandown?.toFixed(2)}</td>
                                      <td>{format(getUtcDate(group.header.adStartDate!), 'M/d/yyyy')}</td>
                                    </tr>
                                  </Box>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {subgroup.upCs.split(',').map((upc, index) => {
                                    return (
                                      <Box key={`upc-${index}`} sx={[styles.propertyDetails]}>
                                        <label>{upc}</label>
                                        <span>{getItemByUpc(upc)?.description}</span>
                                      </Box>
                                    );
                                  })}
                                </td>
                              </tr>
                            </table>
                          );
                        })}
                      </Box>
                    </Box>
                  );
                })}
              </>
            )}
            {adModules?.length > 0 && (
              <>
                <Box sx={[styles.subTitle, styles.sectionSpace]}>Modules</Box>
                {adModules.map(({ header, contentItems }, index) => {
                  return (
                    <Box sx={[styles.subSectionSpace]} key={`module-${index}`}>
                      <Box sx={[styles.propertyDetails, styles.leftDetails]}>
                        <Box sx={[styles.smallTitle]}>{header.adGroupName}</Box>
                        <Box>
                          <SelectedStoresIconPopover stores={{ storeGroupIds: [], storeIds: header.stores.split(',').map(parseInt) }} />
                        </Box>
                      </Box>
                      <Box sx={[styles.rightDetails]}>
                        <Box sx={[styles.propertyDetails]}>
                          <table>
                            <tr>
                              <td>
                                <table>
                                  <tr>
                                    <td>
                                      <Box component="table" sx={[styles.gridTable]}>
                                        <thead>
                                          <th>srp</th>
                                          <th>cc</th>
                                          <th>oi</th>
                                          <th>bb</th>
                                          <th>cmap</th>
                                          <th>scan</th>
                                          <th>start</th>
                                        </thead>
                                        <tr>
                                          <td>0/0.00</td>
                                          <td>{header.caseListCost?.toFixed(2)}</td>
                                          <td>{header.oiAllow?.toFixed(2)}</td>
                                          <td>{header.billback?.toFixed(2)}</td>
                                          <td>{header.cmap?.toFixed(2)}</td>
                                          <td>{header.scandown?.toFixed(2)}</td>
                                          <td>{format(getUtcDate(header.adStartDate!), 'M/d/yyyy')}</td>
                                        </tr>
                                      </Box>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Box component="table" sx={[styles.gridTable]}>
                                  <thead>
                                    <th>start</th>
                                    <th>vendor</th>
                                    <th>dept</th>
                                  </thead>
                                  <tr>
                                    <td>{format(getUtcDate(header.adStartDate!), 'M/d/yyyy')}</td>
                                    <td>{getShipperContainerByOrderCode(header.adGroupName)?.item?.suppliers![0].name}</td>
                                    <td>{contactItems[0].department}</td>
                                  </tr>
                                </Box>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Box component="table" sx={[styles.gridTable]}>
                                  <thead>
                                    <th>UPC</th>
                                    <th>Case</th>
                                    <th>Pack</th>
                                  </thead>
                                  {contentItems.map((item) => (
                                    <>
                                      <tr key={item.upc}>
                                        <td>{item.upc}</td>
                                        <td>{item.cases.toFixed(2)}</td>
                                        <td>{getShipperContainerTotalCountByContentItemUpc(header.adGroupName, item.upc) ?? 0}</td>
                                      </tr>
                                      <tr>
                                        <td colSpan={3}>{getItemByUpc(item.upc)?.description}</td>
                                      </tr>
                                    </>
                                  ))}
                                </Box>
                              </td>
                            </tr>
                          </table>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </>
            )}
          </Box>
          {(fields.length > 0 || adModules?.length > 0) && (
            <Box sx={[styles.exportButtonContainer]}>
              {exporting && <CircularProgress sx={[styles.exportButtonProgress]} color="secondary" />}
              <Button
                id={`export-${id}-submit`}
                text={exporting ? 'Exporting ...' : 'Export'}
                variant="rounded-sides"
                color="blue"
                onClick={handleSubmit(exportSubmit)}
              ></Button>
            </Box>
          )}
        </Box>
      </PageBody>
      {showUnsavedChanges && (
        <ConfirmationDialog
          title={'Unsaved changes'}
          message={`Are you sure you want to continue?`}
          open={showUnsavedChanges}
          onAccept={() => navigate(RouteEnum.Dashboard)}
          onCancel={() => {
            setShowUnsavedChanges(false);
          }}
          onClose={() => {
            setShowUnsavedChanges(false);
          }}
          acceptLabel="Yes"
          cancelLabel="No"
        ></ConfirmationDialog>
      )}
    </>
  );
}
