import { Box, inputLabelClasses } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { IContractItem, IContractTermForLumpSumDigitalReward, IDepartment, ITermType, IContractTermForItem } from '../../../../../models';
import { useApplicationContextState } from '../../../../../contexts/ApplicationContext';
import DbgDialog from '../../../../components/dialogs/DbgDialog';
import DbgSelect from '../../../../components/inputs/DbgSelect';
import DbgNumberField from '../../../../components/inputs/DbgNumberField';
import { DbgButtonSize, TermTypeCategory } from '../../../../../models/enums';
import { getBlueButtonStyleProps, getLightGreyButtonStyleProps, getRedTextButtonStyleProps } from '../../../../../styles/themes';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import DbgDatePicker from '../../../../components/inputs/DbgDatePicker';
import { DbgRoundedButton } from '../../../../components/buttons/DbgRoundedButton';
import { ArrayUtils } from '../../../../../utilities/ArrayUtility';
import { ContractTermModalStyles } from './styles/ContractTermModalStyles';
import Joi from 'joi';
import { MAX_AMOUNT } from '../../../../../services/validationService';
import { NumberToCurrencyString } from '../../../../../utilities/NumberUtility';
import { joiResolver } from '@hookform/resolvers/joi';
import { AutoComplete } from '@dierbergs-markets/react-component-library';
import { getDaysDifference } from '../../../../../utilities/DateUtils';

export interface IContractTermModalState {
  contractId: number;
  uniqueId: string;
  termTypeId?: number;
  termTypeCategory?: string;
  amount?: number;
  startDate?: Date;
  endDate?: Date;
  termUnitOfMeasureId?: number;
  departmentId?: string;
  effectiveDate?: Date;
  digitalRewards?: IContractTermForLumpSumDigitalReward;
  contractItems?: IContractItem[] | null;
  contractTermsForItem?: IContractTermForItem[] | null;
  isAdTermType?: boolean;
}

interface IProps {
  initialState: IContractTermModalState;
  onContinue: (termState: IContractTermModalState) => void;
  onCancel: () => void;
  onDelete: () => void;
}

const { styles } = ContractTermModalStyles;

const ContractTermModal = (props: IProps) => {
  const [selectedTermType, setSelectedTermType] = useState<ITermType | undefined>(undefined);
  const [termTypeOptions, setTermTypeOptions] = useState<any[]>([]);
  const [departmentOptions, setDepartmentOptions] = useState<IDepartment[]>([]);
  const [overrideAmount, setOverrideAmount] = useState<number | undefined>(undefined);
  const [endDateWarning, setEndDateWarning] = useState<string>('');
  const [startDateWarning, setStartDateWarning] = useState<string>('');
  const [effectiveDateWarning, setEffectiveDateWarning] = useState<string>('');
  const [digitalRewardsEndDateWarning, setDigitalRewardsEndDateWarning] = useState<string>('');
  const [digitalRewardsStartDateWarning, setDigitalRewardsStartDateWarning] = useState<string>('');
  const [UnitOfMeasureWarning, setUnitOfMeasureWarning] = useState<string | null>(null);
  const [lumpSumDateTypeLabel, setLumpSumDateTypeLabel] = useState<string>('Effective Date');

  const { referenceData } = useApplicationContextState();

  const defaultValues: IContractTermModalState = { ...props.initialState };
  const departmentRef = useRef<HTMLInputElement>(null);

  const isNewForm = props.initialState?.termTypeId === undefined;
  const isDigitalRewardSelected = selectedTermType?.termTypeId === referenceData?.digitalRewardTermTypeId;

  const oneYearExact = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
  const oneYearAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
  oneYearAgo.setDate(oneYearAgo.getDate() - 1);

  const oneYearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  const currentDate = new Date();
  //Set each comparison date's time to midnight
  oneYearAgo.setHours(0, 0, 0, 0);
  oneYearFromNow.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  //SCHEMA DEFINITIIONS
  const baseSchema = {
    termTypeId: Joi.number().required().messages({ 'any.required': 'Vendor Funded Program is required.' }),
  };

  const digitalRewardsSchema = {
    rewardPointsMultiplier: Joi.number().required().messages({ 'any.required': 'Multiplier is required.' }),
    startDate: Joi.date().greater(oneYearAgo).required().messages({
      'date.base': 'Start Date must be a valid date.',
      'any.required': 'Start Date is required.',
      'date.greater': 'Date cannot be over 1 year in the past. Choose another start date.',
    }),
    endDate: Joi.when('startDate', {
      is: Joi.date().required(),
      then: Joi.date().min(Joi.ref('startDate')),
    })
      .required()
      .messages({
        'date.base': 'End Date must be a valid date.',
        'any.required': 'End Date is required.',
        'date.min': 'End Date must be after the Start Date',
      }),
  };

  const lumpSumValidationSchema = {
    departmentId: Joi.string().required().messages({ 'any.required': 'Department is required.' }),
    effectiveDate: Joi.date()
      .greater(oneYearAgo)
      .required()
      .messages({
        'any.required': `${lumpSumDateTypeLabel} is required.`,
        'date.base': `${lumpSumDateTypeLabel} must be a valid date.`,
        'date.greater': `Date cannot be over 1 year in the past. Choose another ${lumpSumDateTypeLabel.toLowerCase()}.`,
      }),
    isAdTermType: Joi.boolean().default(false),
    amount: Joi.number()
      .required()
      .max(MAX_AMOUNT)
      .messages({
        'any.required': 'Amount is required',
        'number.max': `Amount may not exceed $${NumberToCurrencyString(MAX_AMOUNT)}.`,
      })
      .when('isAdTermType', {
        is: true,
        then: Joi.number().min(0).messages({
          'number.min': 'Amount must be greater than or equal to 0',
        }),
        otherwise: Joi.number().greater(0).messages({
          'number.greater': 'Amount must be greater than 0',
        }),
      }),
  };

  const lumpSumValidationWithDigitalRewardSchema = {
    ...lumpSumValidationSchema,
    digitalRewards: Joi.object().keys(digitalRewardsSchema).required(),
  };

  const upcValidationSchema = {
    startDate: Joi.date().greater(oneYearAgo).required().messages({
      'date.base': 'Start Date must be a valid date.',
      'any.required': 'Start Date is required.',
      'date.greater': 'Date cannot be over 1 year in the past. Choose another start date.',
    }),
    endDate: Joi.when('startDate', {
      is: Joi.date().required(),
      then: Joi.date().min(Joi.ref('startDate')),
    })
      .required()
      .messages({
        'date.base': 'End Date must be a valid date.',
        'any.required': 'End Date is required.',
        'date.min': 'End Date must be after the Start Date',
      }),
    termUnitOfMeasureId: Joi.number().required().messages({ 'any.required': 'Unit of measure is required.' }),
  };

  const contractTermValidationSchema = {
    ...baseSchema,
    ...(selectedTermType?.category === TermTypeCategory.LumpSum
      ? isDigitalRewardSelected
        ? lumpSumValidationWithDigitalRewardSchema
        : lumpSumValidationSchema
      : selectedTermType?.category === TermTypeCategory.Upc && upcValidationSchema),
  };

  //'unknown()' tells Joi to ignore properties that aren't part of the validation schema - e.g., contractId, uniqueId, etc...
  const validationSchema = Joi.object(contractTermValidationSchema).unknown();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<IContractTermModalState>({ defaultValues, resolver: joiResolver(validationSchema) });

  const onSubmit: SubmitHandler<IContractTermModalState> = (data: IContractTermModalState) => handleConfirm(data);

  //USE EFFECTS

  //Validation for Unit Of Measure
  useEffect(() => {
    validateUnitOfMeasure();
  }, [watch('termUnitOfMeasureId')]);

  //Validation for Start Date
  useEffect(() => {
    validateStartDate();
  }, [watch('startDate')]);

  //Validation for End Date
  useEffect(() => {
    validateEndDate();
  }, [watch('endDate')]);

  //Validation for digital rewards Start Date
  useEffect(() => {
    validateDigitalRewardsStartDate();
  }, [watch('digitalRewards.startDate')]);

  //Validation for digital rewards End Date
  useEffect(() => {
    validateDigitalRewardsEndDate();
  }, [watch('digitalRewards.endDate')]);

  //Validation for Effective Date
  useEffect(() => {
    validateEffectiveDate();
  }, [watch('effectiveDate')]);

  useEffect(() => {
    if (selectedTermType?.category === TermTypeCategory.LumpSum) {
      setValue('startDate', undefined);
      setValue('endDate', undefined);
      setValue('termUnitOfMeasureId', undefined);
      setValue('amount', props.initialState?.amount ?? overrideAmount ?? selectedTermType.defaultAmount ?? undefined);
      setValue('digitalRewards', props.initialState?.digitalRewards ?? ({} as IContractTermForLumpSumDigitalReward));
      validateEffectiveDate();
    } else if (selectedTermType?.category === TermTypeCategory.Upc) {
      setValue('effectiveDate', undefined);
      setValue('departmentId', undefined);
      setValue('amount', undefined);
      validateStartDate();
      validateEndDate();
      validateDigitalRewardsStartDate();
      validateDigitalRewardsEndDate();
    }
  }, [setValue, selectedTermType]);

  useEffect(() => {
    if (!referenceData) return;

    const selectedTermTypeId = watch('termTypeId');
    if (selectedTermTypeId) {
      setSelectedTermType(referenceData.termTypes.byId[selectedTermTypeId]);
      if (!props.initialState.termUnitOfMeasureId)
        setValue('termUnitOfMeasureId', referenceData.termTypes.byId[selectedTermTypeId].termUnitOfMeasureId);

      const termType = referenceData.termTypes.byId[selectedTermTypeId];

      if (termType.category === TermTypeCategory.LumpSum) {
        setLumpSumDateTypeLabel(termType.isAd ? 'Ad Date' : 'Effective Date');
        setValue('isAdTermType', termType.isAd);
      }
    }

    //can maintain original inactive selections
    const visibleTermTypes = referenceData.termTypes.all.filter((q) => q.isActive || q.termTypeId === props.initialState.termTypeId);
    const visibleDepartments = referenceData.departments.all.filter((q) => q.isActive || q.id === props.initialState.departmentId);

    const options: any[] = [];
    const upcTermTypes = visibleTermTypes.filter((q) => q.category === 'UPC');
    const lumpSumTermTypes = visibleTermTypes.filter((q) => q.category === 'LUMP SUM');

    options.push({ text: 'UPC', value: 'UPC', disabled: true });
    ArrayUtils.orderBy(upcTermTypes, (x) => x.sortOrder).map((i) => {
      options.push({ text: `${i.name}`, value: i.termTypeId });
    });
    options.push({ text: 'Vendor Funded Program', value: 'LumpSum', disabled: true });

    ArrayUtils.orderBy(lumpSumTermTypes, (x) => x.sortOrder).map((i) => {
      options.push({ text: `${i.name}`, value: i.termTypeId });
    });

    setTermTypeOptions(options);
    setDepartmentOptions(ArrayUtils.orderBy(visibleDepartments, (x) => x.name));
    clearErrors();
  }, [referenceData, watch('termTypeId')]);

  // FUNCTIONS
  /*
  This function is complex. Here is a description of the desired behavior.
  Given a Billback promotion is selected, 
  when a date range is not a minimum of three weeks, (really 20 days)
  when a date range does not start on Monday and end on a Sunday
  then warn the user with an error message 
  and ALLOW them to save 
  */

  //warning
  function validateCaseBillBack(): void {
    const endDate = watch('endDate');
    const startDate = watch('startDate');
    const caseBillBackWarningMessage =
      'Case bill back will be billed for a total duration of 3 weeks: the week of the ad, the week before AND the week following the ad beginning on Monday and ending on Sunday. ';

    if (endDate && startDate && isBillBackPromotion(selectedTermType?.termTypeId)) {
      const daysDifference: number = getDaysDifference(startDate, endDate);
      if (daysDifference < 20 || startDate.getDay() !== 1 || endDate.getDay() !== 0) {
        if (!endDateWarning.includes(caseBillBackWarningMessage)) {
          setEndDateWarning(endDateWarning + caseBillBackWarningMessage);
        }
      } else {
        if (endDateWarning.includes(caseBillBackWarningMessage)) {
          setEndDateWarning(endDateWarning.replace(new RegExp(caseBillBackWarningMessage, 'g'), ''));
        }
      }
    }
  }

  //warning
  function validateUnitOfMeasure(): void {
    //check inital state.
    const newUnitOfMeasureId = watch('termUnitOfMeasureId');
    const initialUnitOfMeasureId = props.initialState?.termUnitOfMeasureId;
    setUnitOfMeasureWarning(null);

    //if we are changing the term from one random weight to another
    if (newUnitOfMeasureId && initialUnitOfMeasureId && newUnitOfMeasureId !== initialUnitOfMeasureId) {
      const newTermIsForRandomWeight = referenceData?.termTypeUnitsOfMeasure.byId[newUnitOfMeasureId]?.forRandomWeight ?? false;
      //If all of the items with the old isRandomWeight are 0 let it go.
      let amountIndex = -1;
      props.initialState.contractTermsForItem?.forEach((term, i) => {
        if (term.uniqueId === props.initialState.uniqueId) {
          amountIndex = i;
        }
      });

      let allAmountsAreZero = false;
      if (amountIndex !== -1) {
        allAmountsAreZero =
          props.initialState.contractItems?.every(
            (contractItem) => contractItem.amounts[amountIndex] === 0 || contractItem.amounts[amountIndex] === undefined
          ) ?? true;
      }

      const allItemsAreRandomWeight = props.initialState.contractItems?.every((contractItem) => contractItem.item?.isRandomWeight === true);
      const allItemsAreNotRandomWeight = props.initialState.contractItems?.every((contractItem) => contractItem.item?.isRandomWeight === false);
      if (
        (allItemsAreRandomWeight && newTermIsForRandomWeight) ||
        (allItemsAreNotRandomWeight && !newTermIsForRandomWeight) ||
        allAmountsAreZero ||
        newUnitOfMeasureId === initialUnitOfMeasureId
      ) {
        //This doesn't make sense
        setSelectedTermType(referenceData?.termTypes.byId[newUnitOfMeasureId]);
      } else {
        setUnitOfMeasureWarning('Selection is incompatible with existing amounts.');
      }
    }
  }

  //warning
  function validateStartDate(): void {
    setStartDateWarning('');
    let startDateWarningMessage = '';
    const startDate = watch('startDate');
    if (startDate) {
      if (startDate < currentDate) {
        startDateWarningMessage += 'Date entered is in the past. Is that correct? ';
      }
      if (startDate > oneYearFromNow) {
        startDateWarningMessage += 'Date selected is over 1 year in the future. Is this correct? ';
      }
      if (startDateWarningMessage != '') {
        setStartDateWarning(startDateWarningMessage);
      }
    }
    validateCaseBillBack();
  }

  //warning
  function validateEndDate(): void {
    setEndDateWarning('');
    let endDateWarningMessage = '';
    const endDate = watch('endDate');
    const startDate = watch('startDate');
    if (endDate) {
      if (endDate < currentDate) {
        endDateWarningMessage += 'Date entered is in the past. Is that correct? ';
      }
      if (endDate > oneYearFromNow) {
        endDateWarningMessage += 'Date selected is over 1 year in the future. Is this correct? ';
      }
      if (startDate && isScanPromotion(selectedTermType?.termTypeId)) {
        if (startDate.getDay() !== 2 || endDate.getDay() !== 1) {
          endDateWarningMessage += 'Scan Vendor Funded Programs are typically 7 days which is the duration of the Ad week (Tues-Mon) ';
        }
      }
      if (endDateWarningMessage !== '') {
        setEndDateWarning(endDateWarningMessage);
      }
    }
    validateCaseBillBack();
  }

  //warning
  function validateDigitalRewardsCaseBillBack(): void {
    const endDate = watch('digitalRewards.endDate');
    const startDate = watch('digitalRewards.startDate');
    const caseBillBackWarningMessage =
      'Case bill back will be billed for a total duration of 3 weeks: the week of the ad, the week before AND the week following the ad beginning on Monday and ending on Sunday. ';

    if (endDate && startDate && isBillBackPromotion(selectedTermType?.termTypeId)) {
      const daysDifference: number = getDaysDifference(startDate, endDate);
      if (daysDifference < 20 || startDate.getDay() !== 1 || endDate.getDay() !== 0) {
        if (!endDateWarning.includes(caseBillBackWarningMessage)) {
          setDigitalRewardsEndDateWarning(endDateWarning + caseBillBackWarningMessage);
        }
      } else {
        if (endDateWarning.includes(caseBillBackWarningMessage)) {
          setDigitalRewardsEndDateWarning(endDateWarning.replace(new RegExp(caseBillBackWarningMessage, 'g'), ''));
        }
      }
    }
  }

  //warning
  function validateDigitalRewardsStartDate(): void {
    setDigitalRewardsStartDateWarning('');
    let startDateWarningMessage = '';
    const startDate = watch('digitalRewards.startDate');
    if (startDate) {
      if (startDate < currentDate) {
        startDateWarningMessage += 'Date entered is in the past. Is that correct? ';
      }
      if (startDate > oneYearFromNow) {
        startDateWarningMessage += 'Date selected is over 1 year in the future. Is this correct? ';
      }
      if (startDateWarningMessage != '') {
        setDigitalRewardsStartDateWarning(startDateWarningMessage);
      }
    }
    validateDigitalRewardsCaseBillBack();
  }

  //warning
  function validateDigitalRewardsEndDate(): void {
    setDigitalRewardsEndDateWarning('');
    let endDateWarningMessage = '';
    const endDate = watch('digitalRewards.endDate');
    const startDate = watch('digitalRewards.startDate');
    if (endDate) {
      if (endDate < currentDate) {
        endDateWarningMessage += 'Date entered is in the past. Is that correct? ';
      }
      if (endDate > oneYearFromNow) {
        endDateWarningMessage += 'Date selected is over 1 year in the future. Is this correct? ';
      }
      if (startDate && isScanPromotion(selectedTermType?.termTypeId)) {
        if (startDate.getDay() !== 2 || endDate.getDay() !== 1) {
          endDateWarningMessage += 'Scan Vendor Funded Program are typically 7 days which is the duration of the Ad week (Tues-Mon) ';
        }
      }
      if (endDateWarningMessage !== '') {
        setDigitalRewardsEndDateWarning(endDateWarningMessage);
      }
    }
    validateDigitalRewardsCaseBillBack();
  }

  //warning
  function validateEffectiveDate(): void {
    setEffectiveDateWarning('');
    let effectiveDateWarningMessage = '';
    const effectiveDate = watch('effectiveDate');
    if (effectiveDate) {
      if (effectiveDate < currentDate) {
        effectiveDateWarningMessage += 'Date entered is in the past. Is that correct? ';
      }
      if (effectiveDate > oneYearFromNow) {
        effectiveDateWarningMessage += 'Date selected is over 1 year in the future. Is this correct? ';
      }
      setEffectiveDateWarning(effectiveDateWarningMessage);
    }
  }

  function getValidationTypeCode(termTypeId: number | undefined): string {
    if (!referenceData || !termTypeId) return '';
    return referenceData.termTypes.byId[termTypeId].validationTypeCode ?? '';
  }

  function isScanPromotion(termTypeId: number | undefined): boolean {
    return getValidationTypeCode(termTypeId) === 'SCAN';
  }

  function isBillBackPromotion(termTypeId: number | undefined): boolean {
    return getValidationTypeCode(termTypeId) === 'BILLBACK';
  }

  function SearchDepartments(query: string): IDepartment[] {
    let result: IDepartment[] = [];
    if (!departmentOptions) return result;
    result = query.length > 0 ? departmentOptions.filter((q) => q.name.toLowerCase().includes(query.toLowerCase())) : departmentOptions;

    return result;
  }

  function handleConfirm(data: IContractTermModalState) {
    if (selectedTermType?.category === TermTypeCategory.Upc) {
      props.onContinue({
        ...props.initialState,
        termTypeId: data.termTypeId,
        termUnitOfMeasureId: data.termUnitOfMeasureId,
        startDate: data.startDate,
        endDate: data.endDate,
        amount: undefined,
        departmentId: undefined,
        effectiveDate: undefined,
        digitalRewards: undefined,
      });
    } else if (selectedTermType?.category === TermTypeCategory.LumpSum) {
      props.onContinue({
        ...props.initialState,
        termTypeId: data.termTypeId,
        departmentId: data.departmentId,
        effectiveDate: data.effectiveDate,
        amount: data.amount,
        termUnitOfMeasureId: undefined,
        startDate: undefined,
        endDate: undefined,
        digitalRewards: isDigitalRewardSelected ? data.digitalRewards : undefined,
      });
    }
  }

  return (
    <DbgDialog
      id="NewContractTermModal"
      open={true}
      sx={styles.dbgDialogOverrides}
      onConfirm={props.onDelete}
      title={`${isNewForm ? 'Add' : 'Edit'} Vendor Funded Program`}
      confirmText={'Delete Vendor Funded Program'}
      confirmButtonTheme={getRedTextButtonStyleProps()}
    >
      {referenceData && (
        <>
          <Box sx={styles.form}>
            <Box sx={[styles.formRow, styles.termType]}>
              <Controller
                control={control}
                name={'termTypeId'}
                render={({ field }) => (
                  <DbgSelect
                    id={'termTypeSelect'}
                    label="Vendor Funded Program"
                    disabled={props.initialState.termTypeId !== undefined}
                    sx={[styles.dbgSelectOverrides]}
                    items={termTypeOptions}
                    onChange={field.onChange}
                    value={field.value || ''}
                    errormessage={errors.termTypeId?.message}
                  />
                )}
              />
            </Box>
            {selectedTermType?.category === TermTypeCategory.Upc && (
              <>
                <Box sx={endDateWarning || startDateWarning ? [styles.formRowWithMsg] : [styles.formRow]}>
                  <Box sx={[styles.formRowSplit]}>
                    <Controller
                      control={control}
                      name={'startDate'}
                      render={({ field }) => (
                        <DbgDatePicker
                          id="startDate"
                          label="Starts on"
                          value={field.value || ''}
                          onChange={field.onChange}
                          errormessage={errors.startDate?.message}
                          gridPosition={'left'}
                          warningmessage={startDateWarning}
                          minimumDate={oneYearExact}
                        />
                      )}
                    />
                  </Box>
                  <Box sx={[styles.formRowSplit]}>
                    <Controller
                      control={control}
                      name={'endDate'}
                      render={({ field }) => (
                        <DbgDatePicker
                          id="endDate"
                          label="Ends on"
                          value={field.value || ''}
                          onChange={field.onChange}
                          errormessage={errors.endDate?.message}
                          gridPosition={'right'}
                          warningmessage={endDateWarning}
                        />
                      )}
                    />
                  </Box>
                </Box>
                <Box sx={[styles.formRow]}>
                  <Controller
                    control={control}
                    name={'termUnitOfMeasureId'}
                    render={({ field }) => (
                      <DbgSelect
                        id={'unitOfMeasureSelect'}
                        label="Unit of Measure"
                        sx={[styles.dbgSelectOverrides]}
                        items={ArrayUtils.orderBy(referenceData.termTypeUnitsOfMeasure.all, (x) => x.name).map((i) => {
                          return { text: `${i.name}`, value: i.termUnitOfMeasureId };
                        })}
                        onChange={field.onChange}
                        value={field.value || ''}
                        errormessage={errors.termUnitOfMeasureId?.message || (UnitOfMeasureWarning ?? '')}
                      />
                    )}
                  />
                </Box>
              </>
            )}
            {selectedTermType?.category === TermTypeCategory.LumpSum && (
              <>
                <Box sx={[styles.formRow]}>
                  <Controller
                    control={control}
                    name={'departmentId'}
                    render={({ field }) => (
                      <AutoComplete
                        id={'departmentSelect'}
                        label="Department"
                        sx={[styles.dbgSelectOverrides]}
                        inputRef={departmentRef}
                        initialValue={(field.value && referenceData.departments.byId[field.value]) || undefined}
                        queryOptions={SearchDepartments}
                        onSelected={(d?) => field.onChange(d?.id)}
                        valueExtractor={(option) => option.id}
                        textExtractor={(option) => option.name}
                        optionRender={(option) => <Box>{option.name}</Box>}
                        characterThreshold={1}
                        errormessage={errors.departmentId?.message}
                      />
                    )}
                  />
                </Box>
                <Box sx={[styles.formRow]}>
                  <Box sx={{ ...{ width: '50%', marginRight: '13px' }, ...(errors.effectiveDate?.message ? { marginBottom: '10px' } : {}) }}>
                    <Controller
                      control={control}
                      name={'effectiveDate'}
                      render={({ field }) => (
                        <DbgDatePicker
                          id="effectiveDate"
                          label={lumpSumDateTypeLabel}
                          value={field.value || ''}
                          onChange={field.onChange}
                          errormessage={errors.effectiveDate?.message}
                          minimumDate={oneYearExact}
                          warningmessage={effectiveDateWarning}
                        />
                      )}
                    />
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <Controller
                      control={control}
                      name={'amount'}
                      render={({ field }) => (
                        <DbgNumberField
                          id={'amountText'}
                          label={'Amount'}
                          sx={{
                            [`& .${inputLabelClasses.shrink}`]: {
                              transform: 'translate(138px, 0px) scale(1.0)',
                            },
                          }}
                          onChange={(event: any) => {
                            if (event != '' && !Number.isNaN(event) && selectedTermType?.defaultAmount != event) {
                              setOverrideAmount(event);
                            }
                            return field.onChange(event);
                          }}
                          value={field.value}
                          decimals={2}
                          prefix="$"
                          errormessage={errors.amount?.message}
                        />
                      )}
                    />
                  </Box>
                </Box>
                {isDigitalRewardSelected && (
                  <>
                    <Box sx={digitalRewardsEndDateWarning || digitalRewardsStartDateWarning ? [styles.formRowWithMsg] : [styles.formRow]}>
                      <Box sx={[styles.formRowSplit]}>
                        <Controller
                          control={control}
                          name={'digitalRewards.startDate'}
                          render={({ field }) => (
                            <DbgDatePicker
                              id="digitalRewards.startDate"
                              label="Starts on"
                              gridPosition={'left'}
                              value={field.value || ''}
                              onChange={field.onChange}
                              errormessage={errors.digitalRewards?.startDate?.message}
                              warningmessage={digitalRewardsStartDateWarning}
                              minimumDate={oneYearExact}
                            />
                          )}
                        />
                      </Box>
                      <Box sx={[styles.formRowSplit]}>
                        <Controller
                          control={control}
                          name={'digitalRewards.endDate'}
                          render={({ field }) => (
                            <DbgDatePicker
                              id="digitalRewards.endDate"
                              label="Ends on"
                              gridPosition={'right'}
                              value={field.value || ''}
                              onChange={field.onChange}
                              errormessage={errors.digitalRewards?.endDate?.message}
                              warningmessage={digitalRewardsEndDateWarning}
                            />
                          )}
                        />
                      </Box>
                    </Box>
                    <Box sx={[styles.formRow]}>
                      <Controller
                        control={control}
                        name={'digitalRewards.rewardPointsMultiplier'}
                        render={({ field }) => (
                          <DbgSelect
                            id={'rewardMultiplierSelect'}
                            label="Reward Points Multiplier"
                            sx={[styles.dbgSelectOverrides]}
                            items={[...(referenceData?.rewardPointsMultipliers ?? [])]}
                            onChange={field.onChange}
                            value={field.value || ''}
                            errormessage={errors.digitalRewards?.rewardPointsMultiplier?.message}
                          />
                        )}
                      />
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>
          <Box sx={[styles.actions]}>
            <DbgRoundedButton
              dbgButtonSize={DbgButtonSize.Large}
              onClick={props.onCancel}
              sx={styles.cancelButton}
              styleProps={getLightGreyButtonStyleProps()}
              id={`DialogNewContractTermModalCancel`}
            >
              {'Cancel'}
            </DbgRoundedButton>
            <DbgRoundedButton
              disabled={!!UnitOfMeasureWarning}
              dbgButtonSize={DbgButtonSize.Large}
              onClick={handleSubmit(onSubmit)}
              sx={styles.confirmButton}
              styleProps={getBlueButtonStyleProps()}
              id={`DialogNewContractTermModalSubmit`}
            >
              {`${isNewForm ? 'Add' : 'Save'} Vendor Funded Program`}
            </DbgRoundedButton>
          </Box>
        </>
      )}
    </DbgDialog>
  );
};

export default ContractTermModal;
