import { defaultColors } from '../../../../styles/variables';
import { formControlClasses } from '@mui/material';

const styles = {
  root: { padding: '10px 20px 10px 20px', boxSizing: 'border-box', overflow: 'hidden', width: '100%', display: 'flex', flexDirection: 'column' },
  header: { flexGrow: 0, flexShrink: 0 },
  content: { maxHeight: 'calc(100vh - 250px)', overflowY: 'auto', overflowX: 'hidden', boxSizing: 'border-box' },
  search: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  centeredMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'calc(100vh - 300px)',
    marginLeft: '10px',
    fontSize: '16px',
    fontWeight: 500,
    spinner: {
      margin: '10px',
      color: defaultColors.blue,
    },
  },
  startDate: [
    {
      width: '150px',
      [`& .${formControlClasses.root}`]: {
        height: 60,
      },
    },
  ],
  adSite: [
    {
      width: '200px',
      [`& .${formControlClasses.root}`]: {
        height: 60,
      },
    },
  ],

  exportButtonContainer: {
    padding: '10px',
    alignSelf: 'flex-end',
    flexGrow: 0,
    flexShrik: 0,
    position: 'relative',
    display: 'flex',
  },
  exportButtonProgress: {
    position: 'relative !important',
    left: '45px !important',
    top: '10px !important',
    height: '25px !important',
    width: '25px !important',
    marginRight: '5px',
    color: defaultColors.lightGrey,
  },

  titleContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  title: {
    fontSize: '35px',
    marginRight: '14px',
  },
  subTitle: {
    fontSize: '25px',
    marginRight: '14px',
  },
  sectionSpace: {
    paddingTop: '15px',
  },
  subSectionSpace: {
    paddingTop: '10px',
    display: 'flex',
  },
  smallTitle: {
    fontSize: '20px',
    marginRight: '14px',
  },
  groupNameAndCommentContainer: {
    display: 'flex',
    columnGap: '5px',
    marginTop: '5px',

    padding: '5px',
    width: '100%',
  },
  groupNameContainer: {
    width: '30%',
    minxWidth: '280px',
    maxWidth: '350px',
    paddingBottom: '40px',
  },
  groupCommentContainer: {
    flexGrow: 2,
    //paddingRight: '20px',
    paddingBottom: '40px',
  },
  groupStoresContainer: {
    minxWidth: '20px',
    paddingTop: '12px',
    paddingBottom: '40px',
  },
  propertyDetails: {
    label: {
      fontWeight: 'bold',
    },
    'label::after': {
      content: '": "',
    },
    'span::after': {
      content: '" "',
    },
  },
  leftDetails: {
    width: '50%',
    paddingRight: '10px',
  },
  rightDetails: {
    width: '50%',
  },
  gridTable: {
    width: '100%',
    borderCollapse: 'collapse',
    margin: '25px 0',
    textAlign: 'left',
    '& th, & td': {
      padding: '12px 15px',
      border: '1px solid #ddd',
    },
    '& thead tr': {
      backgroundColor: '#009879',
      color: '#ffffff',
    },
    '& tbody tr:nth-of-type(even)': {
      backgroundColor: '#f3f3f3',
    },
    '& tbody tr:hover': {
      backgroundColor: '#f1f1f1',
    },
  },
  gridLocations: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))' /* Adjust column width as needed */,
    gap: '10px' /* Adjust gap between items */,
    margin: '20px',
    '& div': {
      padding: '10px',
      border: '1px solid #ccc',
      textAlign: 'center',
    },
  },
  contractPageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  headerBtn: {
    color: defaultColors.darkGrey,
    backgroundColor: defaultColors.transparent,
    fontWeight: 500,
    fontSize: '24px',
    marginBottom: 'auto',
    marginTop: 'auto',
    width: 'initial',
    padding: 0,
    '&:hover': {
      backgroundColor: defaultColors.lightGrey,
    },
  },
  headerBtnIcon: {
    fontSize: '22px',
    width: '22px',
  },
};

export const AdPlannerExportPageStyles = {
  styles,
};
